<template>
  <div class="container">
    <div class="total">
      <span class="sale-date">本期装备社开卖时间 {{ saleDate }}</span>
      <div class="data">
        <div class="left">
          <span class="val">{{ summary.subscription_count }}</span>
          <span class="label">订阅数</span>
        </div>
        <div class="right">
          <div class="item">
            <div class="label">订单数</div>
            <div class="val">{{ summary.order_count }}</div>
          </div>
          <div class="item">
            <div class="label">订单总金额</div>
            <div class="val price">{{ summary.amount.value }}</div>
          </div>
          <div class="item">
            <div class="label">装备社收入</div>
            <div class="val price">{{ summary.income.value }}</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 订单数 -->
    <div class="chart-box">
      <div class="title-box">
        <span class="title">订单数</span>
      </div>
      <div ref="orderCount" class="chart"></div>
      <div class="guide" @click="handleToSaleGuide">
        <span class="content">装备社大卖指南</span>
        <div class="btn">去看看</div>
      </div>
      <div class="guide" @click="handleToBrandFriend">
        <span class="content">经营装备社，领品牌好礼</span>
        <img 
          class="icon-new" 
          src="https://assets.cumen.fun/upload/017cdaf1-c5fa-788e-eea2-d0ee249f902b/018a69be-dffc-0862-2c8e-051b2d20f6bf?x-oss-process=style/origin" 
          alt=""
        >
        <div class="btn">去看看</div>
      </div>
    </div>
    <!-- 订阅数 -->
    <div class="chart-box">
      <div class="title-box">
        <span class="title">订阅数</span>
      </div>
      <div ref="subCount" class="chart"></div>
    </div>
    <!-- 订单总金额 -->
    <div class="chart-box">
      <div class="title-box">
        <span class="title">订单总金额</span>
      </div>
      <div ref="lumpSum" class="chart"></div>
    </div>
    <!-- 装备社收入 -->
    <div class="chart-box">
      <div class="title-box">
        <span class="title">装备社收入</span>
      </div>
      <div ref="totalRevenue" class="chart"></div>
    </div>
    <div class="loading"></div>
  </div> 
</template>

<script>
import * as echarts from 'echarts/core'
import { LineChart } from 'echarts/charts'
import {
  TitleComponent,
  TooltipComponent,
  GridComponent,
  DatasetComponent,
  TransformComponent,
  DataZoomComponent,
} from 'echarts/components';
import { LabelLayout, UniversalTransition } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';
echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  DatasetComponent,
  TransformComponent,
  DataZoomComponent,
  LineChart,
  LabelLayout,
  UniversalTransition,
  CanvasRenderer
]);
import $fetch from "@/utils/fetch.js";
export default {
  data() {
    return {
      clubId: "",
      saleDate: "",
      currentSale: {},
      statsList: [],
      summary: {
        subscription_count: 0,
        order_count: 0,
        amount: {
          value: 0
        },
        income: {
          value: 0
        }
      },
    }
  },
  created() {
    document.title = "装备社数据中心";
    const { clubId, auth } = this.$route.query;
    this.clubId = clubId;
    this.auth = auth;
  },
  mounted() {
    this.handleInitChart(); 
  },
  methods: {
    /** 初始化图表 */
    async handleInitChart() {
      const { clubId, auth } = this;
      const {
        current_sale, stats_list, summary
      } = await $fetch.fetch("xx.cumen.equip.v1.EquipService/GetEquipOperateStats", { 
          clubId 
      }, { Authorization: auth });
      this.currentSale = current_sale;
      this.statsList = stats_list;
      this.summary = summary;

      /** 格式化开售时间段 */ 
      const start = this.handleFormatDate(current_sale.sale_start_time);
      const end = this.handleFormatDate(current_sale.sale_end_time);
      this.saleDate = `${start}-${end}`

      /** 渲染订单数图表 */
      const xDatas = stats_list.map((_item, idx) => '第' + this.numberToChinese(idx + 1) + '期\n\n');

      const orders = stats_list.map(item => item.order_count);
      this.renderEcharts(echarts.init(this.$refs.orderCount), xDatas, orders);

      /** 渲染订阅数图表 */
      const subs = stats_list.map(item => item.subscription_count);
      this.renderEcharts(echarts.init(this.$refs.subCount), xDatas, subs);

      /** 渲染总金额数图表 */
      const lumps = stats_list.map(item => item.amount.value);
      this.renderEcharts(echarts.init(this.$refs.lumpSum), xDatas, lumps);

      /** 渲染装备社收入数图表 */
      const revenues = stats_list.map(item => item.wait_divide ? '待分账' : item.income.value );
      this.renderEcharts(echarts.init(this.$refs.totalRevenue), xDatas, revenues);
    },
    /** 格式化日期 */ 
    handleFormatDate(target) {
      const date = new Date(target);
      const month = date.getMonth() + 1;
      const day = date.getDate();
      const hour = date.getHours();
      const minutes = date.getMinutes();
      const monthStr = month < 10 ? "0" + month : month;
      const dayStr = day < 10 ? "0" + day : day;
      const hourStr = hour < 10 ? "0" + hour : hour;
      const minutesStr = minutes < 10 ? "0" + minutes : minutes;
      return `${monthStr}.${dayStr} ${hourStr}:${minutesStr}`;
    },
    /** 渲染图表 */ 
    renderEcharts(echarts, xDatas, yDatas) {
      const option = {
        grid: {
          top: '10%', 
          left: '12%',
          right: '10%',
          bottom: '22%',
        },
        dataZoom: [
          {
            type: 'slider',
            show: false,
            xAxisIndex: 0,
            start: 0,
            end: 100,
            filterMode: 'empty',
          }
        ],
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: xDatas,
        },
        yAxis: {
          type: 'value',
          splitLine: {
            interval: 1,
            lineStyle: {
              type: 'dashed',
            }
          }
        },
        tooltip: {
          trigger: 'axis', 
          position: "top",
          axisPointer: {
              type: 'line',
          },
        },
        series: [
          {
            data: yDatas,
            type: 'line',
            smooth: true,
            lineStyle: {
              color: '#72c190',
            },
            itemStyle: {
              color: '#72c190',
            },
            areaStyle: {
              color: 'rgba(0, 0, 0, 0)'
            },
          },
        ]
      }
      echarts.setOption(option);
    },
    numberToChinese(num) {
      var chineseNum = ["零", "一", "二", "三", "四", "五", "六", "七", "八", "九"];
      var chineseUnit = ["", "十", "百"];

      if (num === 10) {
          return chineseUnit[1];
      } else if (num < 10) {
          return chineseNum[num];
      } else if (num < 20) {
          return chineseUnit[1] + chineseNum[num % 10];
      } else {
          var result = "";
          var numStr = num.toString();
          for (var i = 0; i < numStr.length; i++) {
              var digit = parseInt(numStr[i]);
              if (digit !== 0) {
                  result += chineseNum[digit] + chineseUnit[numStr.length - i - 1];
              } else {
                  // 处理连续的零
                  if (result[result.length - 1] !== chineseNum[0]) {
                      result += chineseNum[digit];
                  }
              }
          }
          return result;
      }
    },
    /** 前往订单页面 */ 
    handleToBrandFriend() {
      const { clubId } = this;
      wx.miniProgram.navigateTo({ url: `/packages/goods/pages/brand-friend/index?clubId=${clubId}&alreadyOpenBusinessStrategy=true` });

    },
    /** 前往装备社大卖指南页面 */ 
    handleToSaleGuide() {
      const { clubId } = this;
      wx.miniProgram.navigateTo({ url: `/packages/goods/pages/business-strategy/index?clubId=${clubId}` });
    }
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/global.scss";

.container {
  @include column(flex-start, center);
  width: 100%;
  min-height: 100vh;
  padding-top: px2rem(18);
  background-color: #fafafa;

  > .total {
    @include column;
    width: calc(100% - px2rem(48));
    margin: px2rem(16) px2rem(24);
    height: px2rem(380);
    background: url('https://assets.cumen.fun/upload/017cdaf1-c5fa-788e-eea2-d0ee249f902b/018a15df-3512-5948-a8b5-0fb2121255dd?x-oss-process=style/origin') no-repeat center center / cover;
    border-radius: px2rem(32);

    > .sale-date {
      @include ellipsis;
      max-width: calc(100% - px2rem(60));
      color: #222;
      font-size: px2rem(28);
      margin-top: px2rem(36);
      margin-left: px2rem(30);
      font-family: PingFangSC-Regular, PingFang SC;
    }

    > .data {
      @include row(flex-start, center);
      height: px2rem(216);
      margin-top: px2rem(42);

      > .left {
        @include column(flex-start, center);
        width: px2rem(288);
        margin-left: px2rem(18);
        overflow: hidden;

        > .label {
          color: rgba(34, 34, 34, .4);
          font-size: px2rem(24);
          text-align: center;
        }

        > .val {
          font-size: px2rem(52);
          font-family: "Alibaba PuHuiTi Heavy", Alibaba PuHuiTi, PingFangSC-Semibold;
          text-align: center;
        }
      }

      > .right {
        @include column;
        margin-left: px2rem(16);
        
        > .item {
          @include row;
          height: px2rem(72);
          line-height: px2rem(72);

          > .label {
            flex-shrink: 0;
            width: px2rem(120);
            color: rgba(34, 34, 34, .4);
            font-size: px2rem(24);
            margin-left: px2rem(24);
            white-space: nowrap;
          }

          > .val {
            width: 100%;
            font-size: px2rem(36);
            margin-left: px2rem(24);
            font-family: "Alibaba PuHuiTi Heavy", Alibaba PuHuiTi, PingFangSC-Semibold;
            text-align: center;
          }

          > .price {
            position: relative;
            padding-left: px2rem(20);

            &::before {
              content: "￥";
              position: absolute;
              left: px2rem(-2);
              top: px2rem(3);
              font-size: px2rem(24);
            }
          }
        }
      }
    }
  }

  > .chart-box {
    width: px2rem(702);
    margin-top: px2rem(12);
    padding-bottom: px2rem(32);
    background-color: #fff;
    border-radius: px2rem(32);
    box-shadow: 0 px2rem(8) px2rem(20) 0 rgba(147, 147, 147, 0.1);

    > .title-box {
      @include column(center, flex-start);
      width: auto;
      height: px2rem(88);
      padding: 0 px2rem(28);
      margin-top: px2rem(18);

      > .title {
        position: relative;
        color: #000;
        font-size: px2rem(36);
        font-weight: bold;
        padding-left: px2rem(24);
        line-height: px2rem(36);

        &::before {
          content: "";
          position: absolute;
          left: 0;
          top: px2rem(12);
          width: px2rem(12);
          height: px2rem(12);
          background-color: #1ffd91;
          border-radius: 50%;
        }
      }
    }

    > .chart {
      width: auto;
      margin: 0 px2rem(12);
      height: px2rem(418);
    }

    > .guide {
      @include row(space-between, center);
      width: auto;
      height: px2rem(88);
      margin: 0 px2rem(28);
      padding: 0 px2rem(24);
      background-color: #fafafa;
      border-radius: px2rem(8);

      &:not(:last-child) {
        margin-top: px2rem(32);  
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        box-shadow: inset 0 px2rem(-1) 0 0 rgba(0, 0, 0, 0.1);
      }

      > .content {
        color: #222;
        font-size: px2rem(28);
        font-weight: bold;
      }

      > .icon-new {
        width: px2rem(72);
        height: px2rem(28);
        margin-left: px2rem(4);
        margin-right: auto;
      }

      > .btn {
        color: #1ffd91;
        font-size: px2rem(24);
        width: px2rem(112);
        height: px2rem(48);
        text-align: center;
        font-weight: bold;
        line-height: px2rem(48);
        background-color: #222;
        border-radius: px2rem(40);
      }
    }
  }

  .loading {
    width: 100%;
    height: px2rem(176);
    background: url('https://assets.cumen.fun/upload/017cdaf1-c5fa-788e-eea2-d0ee249f902b/018a15fb-c74e-0179-03a1-be19bacf2f23?x-oss-process=style/origin') no-repeat center center / px2rem(190) px2rem(76);
  }
}
</style>
